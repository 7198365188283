<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="eduClassItems"
            label="교육대상자 구분"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="targetClassCd"
            v-model="searchParam.targetClassCd">
          </c-select>
        </div> -->
        <div v-if="popupParam.targetClassCd === '1'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            label="부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div v-if="popupParam.targetClassCd === '2'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="eduPositionItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            label="직책"
            name="positionCd"
            v-model="searchParam.positionCd">
          </c-select>
        </div>
        <div v-if="popupParam.targetClassCd === '3'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="eduSeniorItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            label="법규/선임"
            name="seniorCd"
            v-model="searchParam.seniorCd">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="대상자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :usePaging="false"
      cardClass="topcolor-orange"
      rowKey="seq"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'education-target-user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          targetClassCd: '1',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "attendeesPositionName",
            field: "attendeesPositionName",
            label: "부서",
            align: "center",
            sortable: true,
          },
          {
            name: "userNm",
            field: "userNm",
            label: "교육대상자명",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      eduClassItems: [
        { code: '1', codeName: '부서' },
        { code: '2', codeName: '직책' },
        { code: '3', codeName: '법규/선임' },
      ],
      eduPositionItems: [
        { code: '1', codeName: '상무' },
        { code: '2', codeName: '이사' },
        { code: '3', codeName: '부장' },
        { code: '4', codeName: '차장' },
        { code: '5', codeName: '과장' },
        { code: '6', codeName: '대리' },
      ],
      eduSeniorItems: [
        { code: '1', codeName: '산업안전보건법/관리감독자' },
        { code: '2', codeName: '산업안전보건법/안전감독자' },
        { code: '3', codeName: '산업안전보건법/보건감독자' },
        { code: '4', codeName: '위험물안전관리법/위험물 안전관리자' },
      ],
      searchParam: {
        plantCd: null,
        deptCd: null,
        keyword: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      userNm: '',
      userId: '',
      chkYmd: '',
      editable: true,
      searchUrl: '',
      selectData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {
    // 'searchParam.targetClassCd'() {
    // },
  },
  methods: {
    init() {
      // role setting
      this.searchParam.targetClassCd = this.popupParam.targetClassCd
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.popupParam.targetClassCd === '1') {
        this.grid.columns = [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "attendeesPositionName",
            field: "attendeesPositionName",
            label: "부서",
            align: "center",
            sortable: true,
          },
        ]
        this.grid.data = [
          {
            seq: 1,
            plantNm: '1사업장',
            attendeesPositionName: '안전환경팀',
          },
          {
            seq: 2,
            plantNm: '1사업장',
            attendeesPositionName: '보건팀',
          },
          {
            seq: 3,
            plantNm: '1사업장',
            attendeesPositionName: '개발자팀',
          },
        ];
      } else if (this.popupParam.targetClassCd === '2') {
        this.grid.columns = [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "attendeesPositionName",
            field: "attendeesPositionName",
            label: "직책",
            align: "center",
            sortable: true,
          },

        ]
        this.grid.data = [
          {
            seq: 1,
            plantNm: '1사업장',
            attendeesPositionCd: '',
            attendeesPositionName: '상무',
          },
          {
            seq: 2,
            plantNm: '1사업장',
            attendeesPositionName: '이사',
          },
          {
            seq: 3,
            plantNm: '1사업장',
            attendeesPositionName: '부장',
          },
        ];
      } else {
        this.grid.columns = [
          {
            name: "plantNm",
            field: "plantNm",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "attendeesPositionName",
            field: "attendeesPositionName",
            label: "법규/선임",
            align: "center",
            sortable: true,
          },
        ]
        this.grid.data = [
          {
            seq: 1,
            plantNm: '1사업장',
            attendeesPositionName: '산업안전보건법/관리감독자',
          },
          {
            seq: 2,
            plantNm: '1사업장',
            attendeesPositionName: '산업안전보건법/안전감독자',
          },
          {
            seq: 3,
            plantNm: '1사업장',
            attendeesPositionName: '위험물안전관리법/위험물 안전관리자',
          },
        ];
      }
    },
    select() {
      this.selectData = this.$refs['table'].selected;
      if (!this.selectData || this.selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '대상자를 선택하세요.', // 대상자를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', {
          selectData: this.selectData,
        });
      }
    },
  }
};
</script>
